@import '~antd/dist/antd.css';
@import 'media-library/dist/index.css';
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

[contenteditable]:focus {
    outline: 0px solid transparent;
}

[contenteditable='true']:empty:before {
    content: attr(placeholder);
    display: block;
    color: #bdbdbd;
}

.ant-collapse-arrow {
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
::-webkit-scrollbar-track {
    box-shadow: none;
}
::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: none;
    border-radius: 10px;
}

.ant-popover-inner-content {
    padding: 3px 2px;
}
