/* Reset code */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* fonts */
@font-face {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../../assets/fonts/CircularStd-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: normal;
    src: url('../../assets/fonts/CircularStd-Book.woff') format('woff');
}

@font-face {
    font-family: 'Circular Std Medium';
    font-style: normal;
    font-weight: normal;
    src: url('../../assets/fonts/CircularStd-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Circular Std Black';
    font-style: normal;
    font-weight: normal;
    src: url('../../assets/fonts/CircularStd-Black.woff') format('woff');
}

html {
    overscroll-behavior-x: none;
}

body {
    margin: 0;
    line-height: 1;
    font-size: 16px;
    user-select: none;
    font-family: Arial, Helvetica, sans-serif;
}

strong {
    font-weight: bold;
}

i {
    font-style: italic;
}

u {
    text-decoration: underline;
}

.uyp span {
    display: inline-block;
    cursor: pointer;
}

.uyp .ant-avatar {
    width: 1.875rem;
    height: 1.875rem;
    line-height: 1.875rem;
    margin-right: 0.625rem;
    cursor: pointer;
}

.uyp span svg {
    width: 0.5rem;
    margin-left: 0.3125rem;
    fill: #757575;
    cursor: pointer;
}

.uypin li a,
.uypin li button {
    display: block;
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid #e5e5e5;
    color: #202124;
    font-family: 'Circular Std Medium';
    border: none;
}

.uypout .ant-popover-inner-content {
    padding: 0;
}

.uypin li a:hover,
.uypin li button:hover {
    background: #f0f3f4;
}

.media-portal-div {
    z-index: 9999;
}

/* ckeditor defualt override  [START] */
.ck.ck-editor__editable_inline[dir='ltr'] {
    text-align: inherit !important;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
    overflow: hidden;
}

.ck.ck-content.ck-editor__editable * {
    margin: 0 !important;
}
.ck.ck-editor__editable_inline {
    padding: 0 !important;
}
/* ckedtor defualt override [END] */

/* resizable div start  */

.react-resizable {
    position: relative;
}
.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}
.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}
.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}
.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}
.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}
.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}
.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}
.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
}

.ant-select-selection-selected-value {
    padding: 5px 26px 0 16px;
}

.App {
    height: 100vh;
    overflow: hidden;
}

/* resizable div end  */
